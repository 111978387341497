@import "~@/styles/variables/variables.scss";





































































































































































































































































































































































































.common-shell {
	@include base-button(150px);
	height: 100%;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	&-content {
		flex: 1;
		display: flex;
		height: 0;
		.left-filter {
			width: 366px;
			height: 100%;
			padding: 18px 20px 12px;
			border-right: 1px solid #f1f1f1;
			box-sizing: border-box;
			overflow: auto;
			.filter-modules {
				padding-bottom: 10px;
				.title {
					font-size: 17px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 600;
					color: #333333;
				}
				.item {
					margin-top: 20px;
				}
			}
			.button-zone {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		.right-table {
			flex: 1;
			width: 0;
			padding: 18px 20px 0;
			.table {
				height: 100%;
			}
		}
	}
}
::v-deep {
	.el-form-item--small .el-form-item__content {
		line-height: inherit;
	}
}
